import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { ReactComponent as AcePdgLogo } from "../assets/img/logo/acepdg-logo.svg";

import { ReactComponent as AcePdgLogoColored } from "../assets/img/logo/acepdg-logo-colored.svg";

export const Navigation = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { whiteBg } = props;
  const [isHidden, setIsHidden] = useState(false);

  const LogoIcon = whiteBg ? AcePdgLogoColored : AcePdgLogo;

  const hideBar = () => {
    const pos = window.pageYOffset;
    pos > 120 ? setIsHidden(true) : setIsHidden(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", hideBar, { passive: true });

    return () => {
      window.removeEventListener("scroll", hideBar);
    };
  }, []);

  let classHide = isHidden && !whiteBg ? "menu-hide" : "";

  let classBg = whiteBg ? "menu-white" : "";

  return (
    <nav
      id="menu"
      className={`navbar navbar-default navbar-fixed-top ${classBg} ${classHide}`}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            className="navbar-brand page-scroll"
            href="# page-top"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            {/* <PDGLogo style={{ height: 48, width: 48 }} /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LogoIcon width={144} />
              {/* Ace PDG
              <span>mobile app</span> */}
            </div>
          </a>{" "}
        </div>

        {isMobile && !whiteBg && (
          <div id="launch-date">
            <p>Available in Google Play and Apple App Store</p>
          </div>
        )}

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="/#home"
                className="page-scroll"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/#about"
                className="page-scroll"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="/#faq"
                className="page-scroll"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                PFE FAQs
              </a>
            </li>

            <li>
              <a
                href="/#about-services"
                className="page-scroll"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                Services
              </a>
            </li>
            {/* <li>
              <a href="#App" className="page-scroll">
                APP
              </a>
            </li> */}

            <li>
              <a
                href="/#contact"
                className="page-scroll"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                Contact Us
              </a>
            </li>

            <li>
              <button
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/us/app/ace-pdg/id6738878262",
                    "_blank"
                  );
                }}
                className={`btn btn-custom btn-custom-primary ${
                  isMobile ? "ml-4" : ""
                }`}
              >
                Start 3-days Trial
              </button>
            </li>

            {/* {isMobile && (
              <li>
                <div id="launch-date">
                  <p>
                    Available in App Store now, launching in Google Play store
                    soon!
                  </p>
                </div>
              </li>
            )} */}
          </ul>
        </div>
        {!isMobile && !whiteBg && (
          <div id="launch-date">
            <p>Available in Google Play and Apple App Store</p>
          </div>
        )}
      </div>
    </nav>
  );
};
